<template>
  <v-switch
    v-model="control.model[control.name]"
    v-bind="control"
    class="jexy-switch"
    @change="$emit('change')"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'Toggle',
  mixins: [ Control ]
}
</script>

<style lang="scss">

.checkbox-label {
  display: inline-block;
  position: relative;
  top: -5px;
}

</style>
